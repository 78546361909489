import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Modal from "../components/Modal";

import imageData from "../components/images";

import { ProGallery } from "pro-gallery";
import "pro-gallery/dist/statics/main.css";

// The options of the gallery (from the playground current state)
const options = {
	galleryLayout: 1,
	gallerySize: 50,
	imageMargin: 12,
	overlayBackground: "rgba(255,0,0,0)",
};

// The size of the gallery container. The images will fit themselves in it
const container = {
	width: window.innerWidth,
	height: window.innerHeight,
};

// The scrollingElement is usually the window, if you are scrolling inside another element, suplly it here
const scrollingElement = window;

const Photo = () => {
	const [visible, setVisible] = useState(false);
	const [img, setImg] = useState(false);
	const [images, setImages] = useState([]);
	const { set } = useParams();

	useEffect(() => {
		setImages(imageData[set].images);
	}, []);

	// The eventsListener will notify you anytime something has happened in the gallery.
	const eventsListener = (eventName, eventData) => {
		if (eventName === "ITEM_CLICKED") {
			setImg({id: eventData.id, url: eventData.url });
			setVisible(true);
		}
	};

	const hideModal = () => setVisible(false);

	return (
		<div className="active-dark">
			{images.length ? (
				<div>
					<div id="photo">
						<ProGallery
							items={images}
							options={options}
							container={container}
							eventsListener={eventsListener}
							scrollingElement={scrollingElement}
						/>
					</div>

					<Modal visible={visible} set={set} img={img} hideModal={hideModal} />
				</div>
			) : (
				""
			)}
		</div>
	);
};

export default Photo;
