import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ProGallery, GALLERY_CONSTS } from "pro-gallery";
import "pro-gallery/dist/statics/main.css";

import imageData from "../components/images";

// The options of the gallery (from the playground current state)
const options = {
	galleryLayout: GALLERY_CONSTS.layout.GRID,
	gallerySize: 50,
	imageMargin: 25,
	overlayBackground: "rgba(255,255,255,0)",
};

// The size of the gallery container. The images will fit themselves in it
const container = {
	width: window.innerWidth,
	height: window.innerHeight,
};

// The scrollingElement is usually the window, if you are scrolling inside another element, suplly it here
const scrollingElement = window;

const PhotoOverview = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [covers, setCovers] = useState([]);

	useEffect(() => {
		const covers = Object.entries(imageData).map(
			([path, { name, cover, images }]) => {
				const image = images.find((i) => i.itemId === cover);
				image.metaData.title = name;
				image.title = name;
				image.metaData.link = {
					url: `${location.pathname}/${path}`,
					target: "_blank",
				};
				return image;
			}
		);
		setCovers(covers.reverse());
	}, []);

	// The eventsListener will notify you anytime something has happened in the gallery.
	const eventsListener = (eventName, eventData) => {
		if (eventName === "ITEM_CLICKED") {
			navigate(eventData.linkUrl);
		}
	};

	return (
		<div className="active-dark">
			<div id="photo-overview" className="po">
				<ProGallery
					items={covers}
					options={options}
					container={container}
					eventsListener={eventsListener}
					scrollingElement={scrollingElement}
				/>
			</div>
		</div>
	);
};

export default PhotoOverview;
