import React from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./index.scss";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Landing from "./pages/Landing";
import PhotoOverview from "./pages/PhotoOverview";
import Photo from "./pages/Photo";
// import Shop from "./pages/Shop";
// import CheckoutForm from "./pages/Checkout";
// import Return from "./pages/Return";

class App extends React.Component {
	render() {
		return (
			<div className="active-dark">
				<Header homeLink="/" logo="symbol-dark" color="color-black" />
				<Router>
					<Routes>
						<Route path="/" element={<Landing />} />
						<Route path="/photo" element={<PhotoOverview />} />
						<Route path="/photo/:set" element={<Photo />} />
						{/* <Route path="/shop/:set/:id" element={<Shop />} />
						<Route path="/checkout" element={<CheckoutForm />} />
						<Route path="/return" element={<Return />} /> */}
					</Routes>
				</Router>
				<Footer />
			</div>
		);
	}
}

export default App;
