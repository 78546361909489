import React from "react";
import { useNavigate } from "react-router-dom";

const Modal = (props) => {
	const navigate = useNavigate();

	const downloadImage = (url) => {
		fetch(url)
			.then((response) => response.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute(
					"download",
					props.imgURL.split("/")[props.imgURL.split("/").length - 1]
				);
				document.body.appendChild(link);
				link.click();
			});
	};

	return props.visible ? (
		<div className="active-dark">
			<div className="modal-bg" onClick={() => props.hideModal()}></div>
			<div className="modal-content">
				<div
					className="modal-img"
					style={{
						background: `url(${props.img.url})`,
						backgroundPosition: "50% 50%",
						backgroundSize: "cover",
					}}
				>
					<img src={props.img.url} alt="" />
					<div className="slide-btn">
						{/* <a
							className="rn-button-style--2 btn-solid modal-btn"
							onClick={() => navigate(`/shop/${props.set}/${props.img.id}`)}
						>
							Kaufen
						</a> */}
						{/* <a
							className="rn-button-style--2 btn-solid modal-btn"
							onClick={() => downloadImage(props.imgURL)}
						>
							Download
						</a> */}
					</div>
				</div>
			</div>
		</div>
	) : (
		""
	);
};

export default Modal;
