import React, { useState, useRef } from "react";
import TextLoop from "react-text-loop";
import emailjs from "emailjs-com";
import {
	VerticalTimeline,
	VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { Link } from "react-router-dom";
import "friendly-challenge/widget";
import "react-vertical-timeline-component/style.min.css";

import about from "../images/bg/DSC04661.jpg";
import contact from "../images/bg/DSC09323.jpg";
import bubble from "../images/sprechblase.svg";

const Landing = () => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");
	const [name_err, setName_Err] = useState(false);
	const [email_err, setEmail_Err] = useState(false);
	const [mail_sent, setMail_Sent] = useState(false);
	const [solution, setSolution] = useState("");
	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();

		if (name.trim() === "" && !name_err) {
			setName_Err(true);
		} else setName_Err(false);

		if (email.trim() === "" && !email_err) {
			setEmail_Err(true);
		} else setEmail_Err(false);

		if (name.trim() === "" || email.trim() === "") return;

		if (!solution || solution.length <= 0) return;
		fetch("https://api.friendlycaptcha.com/api/v1/siteverify", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				solution: solution,
				secret: "A18L1NDSL60GS2G72ICH234DJ9GIPUHU80HGPQP33GL8CK20B63IHF2SM4",
				sitekey: "FCMGJ6DP9UA4V5GO",
			}),
		})
			.then((res) => res.json())
			.then((json) => {
				console.log(json);
				if (!json.success) return;

				emailjs
					.sendForm(
						"service_uqm03mo",
						"template_np1wxyp",
						form.current,
						"user_vcFL63rQeYPm2QyuMk3bX"
					)
					.then(
						(result) => {
							console.log(result.text);
							setName("");
							setEmail("");
							setSubject("");
							setMessage("");
							setMail_Sent(true);

							const a = document.createElement("a");
							a.setAttribute("href", "/#contact");
							a.click();
							a.remove();
						},
						(error) => {
							console.log(error.text);
						}
					);
			});
	};

	window.captchaCallback = (solution) => {
		setSolution(solution);
	};

	return (
		<div className="active-dark">
			<div id="home" className="fix">
				<div className="slider-wrapper">
					<div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25">
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									<div className="inner text-left">
										<span className="pre-title">
											Welcome to my World
										</span>
										<h1 className="title">
											Hi, ich bin Philipp <br />
											<TextLoop>
												<span> Web-Entwickler.</span>
												<span> App-Entwickler.</span>
												<span> Fotograf.</span>
											</TextLoop>{" "}
										</h1>
										<h2>aus Hanau.</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="about" className="rn-about-area ptb--120 bg_color--1">
				<div className="rn-about-wrapper">
					<div className="container">
						<div className="row row--35 align-items-center">
							<div className="col-lg-5 col-md-12">
								<div className="thumbnail">
									<img
										className="w-100"
										src={about}
										alt="About Images"
									/>
								</div>
							</div>

							<div className="col-lg-7 col-md-12">
								<div className="about-inner inner">
									<div className="section-title">
										<h2 className="title">Über mich</h2>
										<p className="description">
											Ich bin Philipp, 26 Jahre alt und
											leidenschaftlicher Web-Entwickler
											und{" "}
											<Link to="/photo">Fotograf</Link>!
											<br></br>
											Neben meinem Beruf als Full Stack
											Entwickler, fotografiere ich gerne,
											mache viel Sport und am Liebsten
											verbringe ich Zeit mit der Familie
											und Freunden.
										</p>
									</div>
									<div className="row mt--30 mt_sm--10">
										<div className="col-lg-6 col-md-12 col-sm-12 col-12">
											<div className="about-us-list">
												<h3 className="title">
													Aufgeschlossen
												</h3>
												<p>
													Wie sagt man so schön:
													<br /> Ausgelernt hat man
													nie! Ich liebe es, Neues
													kennenzulernen, neue
													Techniken und Technologien
													zu erlernen oder eine
													Situation aus einer anderen
													Perspektive zu betrachten.
												</p>
											</div>
										</div>
										<div className="col-lg-6 col-md-12 col-sm-12 col-12">
											<div className="about-us-list">
												<h3 className="title">
													Ehrgeizig
												</h3>
												<p>
													Ziele sind dafür da, um auch
													umgesetzt zu werden. Ein
													Foto soll stimmig auf den
													Betrachter wirken und eine
													Webseite nicht nur
													funktional, sondern auch
													benutzerfreundlich und
													performant sein.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="cv" className="ptb--120">
				<div className="rn-cv-wrapper">
					<div className="container">
						<div className="row row--35 align-items-center">
							<div className="section-title">
								<h2 className="title">Lebenslauf</h2>
							</div>
							<VerticalTimeline>
								<VerticalTimelineElement
									className="vertical-timeline-element--education"
									date="April 2023 - heute"
								>
									<h3 className="vertical-timeline-element-title">
										Full Stack Developer
									</h3>
									<h4 className="vertical-timeline-element-subtitle">
										shift GmbH
									</h4>
								</VerticalTimelineElement>
								<VerticalTimelineElement
									className="vertical-timeline-element--education"
									date="Juni 2022 - Dezember 2022"
								>
									<h3 className="vertical-timeline-element-title">
										Masterarbeit
									</h3>
									<h4 className="vertical-timeline-element-subtitle">
										shift GmbH
									</h4>
									<p>
										Smart Contracts im Lizenzmanagement für
										digitalen Content
									</p>
								</VerticalTimelineElement>
								<VerticalTimelineElement
									className="vertical-timeline-element--education"
									date="Septemper 2021 - heute"
								>
									<h3 className="vertical-timeline-element-title">
										Werkstudent im Bereich Webentwicklung
									</h3>
									<h4 className="vertical-timeline-element-subtitle">
										shift GmbH
									</h4>
								</VerticalTimelineElement>
								<VerticalTimelineElement
									className="vertical-timeline-element--education"
									date="Oktober 2020 - heute"
								>
									<h3 className="vertical-timeline-element-title">
										Master-Studium Medieninformatik
									</h3>
									<h4 className="vertical-timeline-element-subtitle">
										Technische Hochschule Mittelhessen
										Friedberg
									</h4>
									<p>Schwerpunkt Web- und Mobile</p>
								</VerticalTimelineElement>
								<VerticalTimelineElement
									className="vertical-timeline-element--education"
									date="Juli 2020 - September 2020"
								>
									<h3 className="vertical-timeline-element-title">
										Bachelorarbeit im Heraeus Digital Hub
									</h3>
									<h4 className="vertical-timeline-element-subtitle">
										Heraeus infosystems GmbH
									</h4>
									<p>
										Evaluation und Entwicklung eines
										Prototypen mit dem PIM Pimcore.
									</p>
								</VerticalTimelineElement>
								<VerticalTimelineElement
									className="vertical-timeline-element--education"
									date="März 2020 - Mai 2020"
								>
									<h3 className="vertical-timeline-element-title">
										Praktikant im Heraeus Digital Hub
									</h3>
									<h4 className="vertical-timeline-element-subtitle">
										Heraeus infosystems GmbH
									</h4>
									<p>
										Konzeption eines PIM-Systems bei
										Heraeus.
										<br />
										Entwicklung einer ES6-Web-Applikation
										und Konfiguratin einer
										GraphQL-Schnittstelle.
									</p>
								</VerticalTimelineElement>
								<VerticalTimelineElement
									className="vertical-timeline-element--work"
									date="Februar 2020 - heute"
								>
									<h3 className="vertical-timeline-element-title">
										Selbstständiges Nebengewerbe
									</h3>
									<h4 className="vertical-timeline-element-subtitle">
										{" "}
										als Webentwickler
									</h4>
								</VerticalTimelineElement>
								<VerticalTimelineElement
									className="vertical-timeline-element--work"
									date="September 2018 - Februar 2020"
								>
									<h3 className="vertical-timeline-element-title">
										Werkstudent im Bereich Global IT
										Infrastructure Services
									</h3>
									<h4 className="vertical-timeline-element-subtitle">
										Heraeus infosystems GmbH
									</h4>
									<p>Webentwicklung und On-Site Support</p>
								</VerticalTimelineElement>
								<VerticalTimelineElement
									className="vertical-timeline-element--work"
									date="Oktober 2016 - Oktober 2020"
								>
									<h3 className="vertical-timeline-element-title">
										Bachelor-Studium Medieninformatik
									</h3>
									<h4 className="vertical-timeline-element-subtitle">
										Technische Hochschule Mittelhessen
										Friedberg
									</h4>
									<p>Schwerpunkt Web- und Mobile</p>
								</VerticalTimelineElement>
								<VerticalTimelineElement
									className="vertical-timeline-element--work v-timeline-element"
									date="2008 - 2016"
								>
									<h3 className="vertical-timeline-element-title">
										Allgemeine Hochschulereife
									</h3>
									<h4 className="vertical-timeline-element-subtitle">
										Hohe Landesschule Hanau (Gymnasium)
									</h4>
								</VerticalTimelineElement>
							</VerticalTimeline>
						</div>
					</div>
				</div>
			</div>

			<div id="contact" className="rn-contact-area ptb--120 bg_color--1">
				<div className="contact-form--1">
					<div className="container">
						<div className="row row--35 align-items-start">
							<div className="col-lg-6 order-2 order-lg-1">
								<div className="section-title text-left mb--50">
									<h2 className="title">Quatschen?</h2>
									<p className="description">
										Du kannst mich ganz einfach telefonisch:{" "}
										<a href="tel:+4917647967121">
											+49 176 47967121
										</a>{" "}
										oder per Mail:
										<a href="mailto:info@philipp-roiner.de">
											{" "}
											info@philipp-roiner.de
										</a>{" "}
										erreichen.
									</p>
								</div>
								<div className="form-wrapper">
									<form ref={form} onSubmit={sendEmail}>
										<label htmlFor="item01">
											<input
												type="text"
												name="name"
												id="item01"
												value={name}
												onChange={(e) =>
													setName(e.target.value)
												}
												placeholder="Dein Name *"
											/>
											{name_err ? (
												<span className="form-message error">
													Bitte sag mir wie du heißt,
													damit ich dich in meiner
													Antwort ansprechen kann.
												</span>
											) : (
												""
											)}
										</label>
										<label htmlFor="item02">
											<input
												type="text"
												name="email"
												id="item02"
												value={email}
												onChange={(e) =>
													setEmail(e.target.value)
												}
												placeholder="Deine E-Mail *"
											/>
											{email_err ? (
												<span className="form-message error">
													Um dir zu antworten brauche
													ich deine E-Mail-Adresse!
												</span>
											) : (
												""
											)}
										</label>
										<label htmlFor="item03">
											<input
												type="text"
												name="subject"
												id="item03"
												value={subject}
												onChange={(e) =>
													setSubject(e.target.value)
												}
												placeholder="Grund für deine Nachricht"
											/>
										</label>
										<label htmlFor="item04">
											<textarea
												type="text"
												id="item04"
												name="message"
												value={message}
												onChange={(e) =>
													setMessage(e.target.value)
												}
												placeholder="Deine Nachricht"
											/>
										</label>
										<div
											className="frc-captcha dark"
											style={{ marginBottom: "20px" }}
											data-sitekey="FCMGJ6DP9UA4V5GO"
											data-callback="captchaCallback"
										></div>
										<button
											className="rn-button-style--2 btn-solid"
											type="submit"
											value="submit"
											name="submit"
											id="mc-embedded-subscribe"
										>
											Senden
										</button>
									</form>
								</div>
							</div>
							<div className="col-lg-6 order-1 order-lg-2">
								<div className="thumbnail contact-img mb_md--30 mb_sm--30">
									<img src={contact} alt="me" />
									{mail_sent ? (
										<img
											src={bubble}
											alt="bubble"
											className="contact-bubble"
										/>
									) : (
										""
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Landing;
